<template>
  <b-dropdown
    v-if="selectTemplate"
    v-model="selectTemplate"
    aria-role="list"
    scrollable
    ref="templatesdropdown"
    :max-height="300"
    :disabled="disab"
  >
    <template #trigger>
      <b-button
        :label="selectTemplate.title"
        type="is-info is-light"
        rounded
        :icon-left="selectTemplate.icon"
        icon-right="menu-down"
      />
    </template>
    <b-dropdown-item custom v-if="userTemplates.length">
      <h3 class="title is-6 has-text-primary mb-1">
        My templates :
      </h3>
    </b-dropdown-item>
    <b-dropdown-item
      v-for="temp in filterTemplates['user']"
      :key="temp.id"
      :value="temp"
      aria-role="listitem"
      class="game-template-dropdown-item"
    >
      <div class="media">
        <b-icon class="media-left" :icon="temp.icon"></b-icon>
        <div class="media-content">
          <h3 class="has-text-link">{{ temp.title }}</h3>
          <small class="has-text-dark" style="white-space: normal;">{{
            temp.description
          }}</small>
        </div>
      </div>
    </b-dropdown-item>
    <b-dropdown-item
      separator
      v-if="filterTemplates['user'].length && filterTemplates['team'].length"
    >
    </b-dropdown-item>
    <b-dropdown-item custom v-if="filterTemplates['team'].length">
      <h3 class="title is-6 has-text-primary mb-1">
        Team templates :
      </h3>
    </b-dropdown-item>
    <b-dropdown-item
      v-for="temp in filterTemplates['team']"
      :key="temp.id"
      :value="temp"
      aria-role="listitem"
      class="game-template-dropdown-item"
    >
      <div class="media">
        <b-icon class="media-left" :icon="temp.icon"></b-icon>
        <div class="media-content">
          <h3 class="has-text-link">{{ temp.title }}</h3>
          <small class="has-text-dark" style="white-space: normal;">{{
            temp.description
          }}</small>
        </div>
      </div>
    </b-dropdown-item>
    <b-dropdown-item
      separator
      v-if="filterTemplates['user'].length || filterTemplates['team'].length"
    >
    </b-dropdown-item>
    <b-dropdown-item
      custom
      v-if="filterTemplates['user'].length || filterTemplates['team'].length"
    >
      <h3 class="title is-6 has-text-primary mb-1">
        Default templates :
      </h3>
    </b-dropdown-item>
    <b-dropdown-item
      v-for="temp in filterTemplates['default']"
      :key="temp.id"
      :value="temp"
      aria-role="listitem"
      class="game-template-dropdown-item"
    >
      <div class="media">
        <b-icon class="media-left" :icon="temp.icon"></b-icon>
        <div class="media-content">
          <h3 class="has-text-link">{{ temp.title }}</h3>
          <small class="has-text-dark" style="white-space: normal;">{{
            temp.description
          }}</small>
        </div>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DropdownSelectTemplate",
  props: {
    value: Object,
    type: String,
    disab: Boolean
  },
  computed: {
    ...mapGetters([
      "teamAccount",
      "userTemplates",
      "defaultTemplates",
      "teamTemplates"
    ]),
    filterTemplates() {
      return {
        user: this.userTemplates.filter(temp => {
          return !this.type || temp.type === this.type;
        }),
        default: this.defaultTemplates.filter(temp => {
          return !this.type || temp.type === this.type;
        }),
        team: this.teamTemplates.filter(temp => {
          return !this.type || temp.type === this.type;
        })
      };
    }
  },
  mounted() {
    if (!this.value) {
      if (!this.teamAccount) {
        this.selectTemplate = {
          ...this.defaultTemplates.find(temp => {
            return temp.id === "shortbg";
          })
        };
        this.$emit("input", this.selectTemplate);
      } else {
        this.selectTemplate = { ...this.defaultTemplates[0] };
      }
      this.$emit("input", this.selectTemplate);
    } else {
      this.selectTemplate = this.value;
    }
  },
  watch: {
    selectTemplate() {
      this.$emit("input", this.selectTemplate);
    },
    value() {
      this.selectTemplate = this.value;
    }
  },
  data() {
    return {
      selectTemplate: null
    };
  }
};
</script>

<style lang="scss">
.newgame-tab-define {
  .dropdown-menu .dropdown-item.game-template-dropdown-item {
    padding-right: 1em;
    .media-content {
      width: 440px;
      max-width: 100%;
    }
    .media-right {
      margin: 0;
    }
  }
}
</style>

<template>
  <form class="newgame-tab-define" ref="defineform">
    <div class="box">
      <select-game-type
        v-model="chooseType"
        :show-plan="true"
        :show-article="true"
      ></select-game-type>
      <b-field label="Name :" class="pb-2">
        <b-input
          type="text"
          required
          ref="nameinput"
          class="name-input"
          placeholder="Name your new game(s)"
          v-model.trim="newGame.name"
          @keyup.enter.native="sendDefine"
        ></b-input>
      </b-field>
      <b-field
        :label="`Start from a ${gameTypes[chooseType[0]].name} Template :`"
        class="pb-2"
      >
        <b-field grouped>
          <dropdown-select-template
            v-model="selectTemplate"
            :type="chooseType[0]"
            @input="clickChooseTemplate"
          ></dropdown-select-template>
          <p
            class="control pt-2 pl-2 has-text-grey is-italic"
            v-if="selectTemplate"
          >
            {{ selectTemplate.description }}
          </p>
        </b-field>
      </b-field>
      <b-field label="Create several copies ?" grouped>
        <b-numberinput
          :min="1"
          :max="100"
          required
          ref="copiesinput"
          v-model.number="nbInstances"
          @keyup.enter.native="sendDefine"
          type="is-info is-light"
        ></b-numberinput>
        <p
          class="control is-expanded has-text-grey pt-1"
          v-if="nbInstances > 1"
        >
          This will create {{ nbInstances }} games named
          <em>"{{ newGame.name }} 1"</em>, <em>"{{ newGame.name }} 2"</em>...
        </p>
      </b-field>
    </div>
    <b-message type="is-warning" v-if="!availGames[chooseType[0]]" class="mb-3">
      With a Free account, your {{ gameTypes[chooseType[0]].name }} simulations
      can only be played in solo. It is a good way to discover how the app
      works!
      <p v-if="!portalLook">
        To organize multi-player sessions you'll need to
        <router-link
          to="/dashboard/subscription"
          class="has-text-primary has-text-weight-bold"
          >upgrade your account plan</router-link
        >.
      </p>
    </b-message>
    <div class="has-text-centered">
      <b-button
        type="is-info"
        size="is-large"
        class="btn-newgame-next"
        icon-right="arrow-right"
        @click.prevent="sendDefine"
        rounded
      >
        Next
      </b-button>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import DropdownSelectTemplate from "@/components/Dashboard/DropdownSelectTemplate.vue";
import SelectGameType from "@/components/Dashboard/SelectGameType.vue";

export default {
  name: "NewGameDefine",
  components: {
    DropdownSelectTemplate,
    SelectGameType
  },
  props: {
    value: Object,
    nbinst: Number
  },
  computed: {
    ...mapGetters(["gameTypes", "availGames", "allTemplates", "portalLook"])
  },
  created() {
    if (this.nbinst) {
      this.nbInstances = this.nbinst;
    }
    if (!this.value) {
      this.$emit("input", this.newGame);
    } else {
      this.newGame = this.value;
    }
    if (this.newGame.fromTemplate) {
      this.selectTemplate = {
        ...this.allTemplates.find(temp => {
          return temp.title === this.newGame.fromTemplate;
        })
      };
    }
    if (this.newGame.type) {
      this.chooseType[0] = this.newGame.type;
    }
  },
  methods: {
    clickChooseTemplate(temp) {
      this.newGame = JSON.parse(
        JSON.stringify({
          ...this.newGame,
          ...temp.config,
          fromTemplate: temp.title
        })
      );
    },
    sendDefine() {
      let valName = this.$refs.nameinput.checkHtml5Validity();
      let valCopies = this.$refs.copiesinput.checkHtml5Validity();
      let valForm = this.$refs.defineform.checkValidity();
      if (valForm && valName && valCopies) {
        this.$emit("input", this.newGame);
        this.$emit("nextstep");
      }
    }
  },
  watch: {
    nbInstances(newVal) {
      this.$emit("nbinstances", newVal);
    },
    newGame: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true
    },
    chooseType(newVal) {
      if (this.selectTemplate.type !== newVal[0]) {
        this.newGame.type = newVal[0];
        this.selectTemplate = {
          ...this.allTemplates.find(temp => {
            return temp.type === newVal[0];
          })
        };
        this.clickChooseTemplate(this.selectTemplate);
      }
    }
  },
  data() {
    return {
      nbInstances: 1,
      selectTemplate: null,
      chooseType: ["beergame"],
      newGame: {
        name: "",
        players: [],
        type: "beergame",
        week: 0,
        public: true,
        finished: false,
        archived: false,
        deleted: false
      }
    };
  }
};
</script>

<style lang="scss">
.newgame-tab-define {
  .dropdown-menu .dropdown-item.game-template-dropdown-item {
    padding-right: 1em;
    .media-content {
      width: 440px;
      max-width: 100%;
    }
    .media-right {
      margin: 0;
    }
  }
}
</style>

<template>
  <div class="select-game-type">
    <b-field
      :label="
        `Select ${multiSelect ? 'one or several Game Types' : 'the Game Type'}:`
      "
      :class="{ 'mb-4': !showArticle }"
    >
      <div class="columns">
        <div
          class="column is-narrow"
          :key="`choose-${gt.type}`"
          v-for="gt in Object.values(gameTypes)"
          @click="clickType(gt.type)"
        >
          <div
            class="gametype-rounded-select"
            :class="{
              [`${gt.type}-class`]: true,
              'is-active': selectedTypes.includes(gt.type),
              'is-disabled': disabledTypes && disabledTypes.includes(gt.type)
            }"
          >
            <div class="media">
              <figure class="media-left mr-1">
                <p class="image is-48x48">
                  <img :src="gt.logo" />
                </p>
              </figure>
              <div
                class="media-content ml-4 is-size-4 has-text-left"
                :class="{ 'pt-2': !showPlan }"
              >
                <b>{{ gt.name }}</b>
                <h4
                  class="is-size-6 has-text-grey is-size-7-touch"
                  v-if="showPlan"
                >
                  Your Plan:
                  {{
                    availGames[gt.type]
                      ? availGames[gt.type].planName
                      : "Free trial"
                  }}
                </h4>
              </div>
            </div>
          </div>
          <p class="has-text-centered is-size-7 mt-2" v-if="showArticle">
            <a v-if="gt.articleLink" :href="gt.articleLink" target="_blank"
              >Learn more</a
            >
          </p>
        </div>
      </div>
    </b-field>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SelectGameType",
  props: {
    value: Array,
    disabledTypes: Array,
    multiSelect: Boolean,
    showPlan: Boolean,
    showArticle: Boolean
  },
  computed: {
    ...mapGetters(["gameTypes", "availGames", "portalLook"])
  },
  created() {
    if (this.value) {
      this.selectedTypes = this.value;
    }
    if (!this.value) {
      this.$emit("input", this.selectedTypes);
    }
  },
  methods: {
    clickType(newType) {
      if (this.disabledTypes && this.disabledTypes.includes(newType)) {
        return;
      }
      if (this.multiSelect) {
        const index = this.selectedTypes.indexOf(newType);
        if (index > -1) {
          this.selectedTypes.splice(index, 1);
        } else {
          this.selectedTypes.push(newType);
        }
      } else {
        this.selectedTypes = [newType];
      }
    }
  },
  watch: {
    selectedTypes: {
      handler() {
        this.$emit("input", this.selectedTypes);
      },
      deep: true
    }
  },
  data() {
    return {
      selectedTypes: ["beergame"]
    };
  }
};
</script>

<style lang="scss"></style>

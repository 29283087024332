<template>
  <div class="newgame-tab">
    <section class="section">
      <div class="container">
        <div class="columns mb-0">
          <div class="column">
            <h1 class="title is-3">
              Create
              <span class="subtitle is-5" v-if="newGame"
                >{{ newGame.name }}
                <em class="is-size-5" v-if="nbInstances > 1"
                  >(x{{ nbInstances }})</em
                ></span
              >
            </h1>
          </div>
          <div class="column is-6">
            <b-steps
              class="empty-creation-steps"
              size="is-small"
              type="is-primary"
              :has-navigation="false"
              v-model="creationStep"
            >
              <b-step-item label="1. Define" icon="check" :clickable="true">
              </b-step-item>
              <b-step-item
                label="2. Configure"
                icon="check"
                :clickable="validDefine"
              >
              </b-step-item>
              <b-step-item label="3. Share" icon="check"> </b-step-item>
            </b-steps>
          </div>
        </div>
        <b-notification
          :active="showError"
          @close="closeError"
          type="is-danger"
          >{{ showError ? error.message : "" }}</b-notification
        >
        <div v-if="creationStep === 0">
          <create-game-tab-define
            v-model="newGame"
            :nbinst="nbInstances"
            @nbinstances="updateNbInstances"
            @nextstep="creationStep = 1"
          ></create-game-tab-define>
        </div>
        <div v-if="creationStep === 1">
          <div class="has-text-centered mb-3 has-text-weight-bold">
            Review the settings and create your game{{
              nbInstances > 1 ? "s" : ""
            }}
            below.
          </div>
          <component
            v-bind:is="configs[newGame.type]"
            v-if="newGame && newGame.type"
            v-model="newGame"
            :can-edit="true"
            ref="config"
          ></component>
          <!-- <bg-config
            v-if="newGame && newGame.type"
            v-model="newGame"
            :can-edit="true"
            ref="config"
          ></bg-config> -->
          <nav class="columns mt-4 mb-6">
            <div class="column is-4">
              <b-button
                class="btn-newgame-back"
                type="is-info is-light"
                size="is-medium"
                rounded
                icon-left="arrow-left"
                @click="creationStep = 0"
              >
                Back
              </b-button>
            </div>
            <div class="column is-4 has-text-centered">
              <b-button
                class="btn-newgame-submit"
                type="is-info"
                size="is-large"
                :disabled="!validConfig"
                @click="submitCreate"
                rounded
              >
                Create
                {{ nbInstances > 1 ? nbInstances : "" }}
                Game{{ nbInstances > 1 ? "s" : "" }}
              </b-button>
            </div>
          </nav>
        </div>
        <div v-if="creationStep === 2 && justCreatedGames.length">
          <div class="section">
            <h1 class="title is-5 columns">
              <b-icon icon="check" type="is-success" size="is-medium"></b-icon
              ><span class="mt5">
                {{ justCreatedGames.length }} game{{
                  justCreatedGames.length > 1 ? "s" : ""
                }}
                successfuly created
              </span>
            </h1>
          </div>
          <div class="section buttons">
            <b-button
              rounded
              tag="router-link"
              to="/dashboard/games"
              type="is-success"
              size="is-medium"
              icon-left="arrow-left"
            >
              Back to Games List
            </b-button>
            <b-button
              rounded
              v-if="justCreatedGames.length === 1"
              tag="router-link"
              size="is-medium"
              :to="`/game/${justCreatedGames[0].id}`"
              icon-right="import"
              type="is-info"
              >Access the Game</b-button
            >
            <b-button
              rounded
              v-if="teamAccount"
              size="is-medium"
              icon-right="share"
              type="is-warning"
              @click="showShareModal = true"
              >Share link with participants</b-button
            >
          </div>
          <b-modal :active.sync="showShareModal" has-modal-card>
            <share-modal :selected-games="justCreatedGames"></share-modal>
          </b-modal>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ShareModal from "@/components/Dashboard/ShareModal.vue";
import CreateGameTabDefine from "@/views/dashboard/CreateGameTabDefine.vue";

export default {
  name: "NewGame",
  components: { ShareModal, CreateGameTabDefine },
  computed: {
    ...mapGetters(["loading", "error", "showError", "teamAccount"]),
    validDefine() {
      return (
        this.newGame && this.newGame.name.length > 0 && this.nbInstances > 0
      );
    }
  },
  methods: {
    updateNbInstances(newVal) {
      this.nbInstances = newVal;
    },
    submitCreate() {
      if (!this.$refs.config) {
        return;
      }
      if (!this.validConfig) {
        this.$store.commit("setError", {
          message: "Invalid creation parameters"
        });
        return;
      } else {
        let postPromises = [];
        if (this.nbInstances === 1) {
          postPromises.push(this.$store.dispatch("createGame", this.newGame));
        } else {
          for (let ii = 0; ii < this.nbInstances; ii++) {
            let copyGame = JSON.parse(JSON.stringify(this.newGame));
            copyGame.name = this.newGame.name + " " + (ii + 1);
            postPromises.push(this.$store.dispatch("createGame", copyGame));
          }
        }
        Promise.all(postPromises).then(results => {
          this.justCreatedGames = results;
          this.creationStep = 2;
        });
      }
    },
    closeError() {
      this.$store.commit("clearError");
    }
  },
  data() {
    return {
      creationStep: 0,
      showShareModal: false,
      validConfig: true,
      justCreatedGames: [],
      nbInstances: 1,
      newGame: null,
      configs: {
        beergame: () => import("@/components/bg/Create/BgConfig.vue"),
        leangame: () => import("@/components/lean/Create/LeanConfig.vue")
      }
    };
  }
};
</script>

<style lang="scss">
.newgame-tab {
  .empty-creation-steps {
    .step-content {
      display: none;
    }
  }
  ul.step-items {
    margin-bottom: 0.5em;
  }
  .b-steps .step-title {
    font-size: 1rem !important;
    font-weight: normal !important;
  }

  .title.is-5 .mt5 {
    margin-left: 10px;
    margin-top: 5px;
  }
  blockquote {
    margin-top: 1em;
    .spacer {
      margin: 0 0.5em;
    }
  }
}
</style>
